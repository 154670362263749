<template>
	<div class="auth-wrapper auth-v2 reset-password-container">
		<img class="mb-1" src="https://goga.ai/wp-content/uploads/2022/01/05-Logo-GoGa-2.png" alt="">
		<p class="common-text mb-2">Please create your new password:</p>
		<div class="reset-password-form">
			<validation-observer ref="passwordForm">
				<b-form class="auth-login-form mt-2" @submit.prevent="setNewPassword">
					<!-- email -->
					<b-form-group class="text-center">
						<validation-provider
							#default="{ errors }"
							name="Password"
							vid="newPassword"
							rules="required|min:6"
						>
								<label for="new-password" class="form-label mb-1">Your new password</label>
								<b-input-group class="input-group-merge">
									<b-form-input id="new-password" v-model="newPassword"
										class="form-control-merge" :type="newPasswordFieldType" name="new-password" />
									<b-input-group-append is-text>
										<feather-icon class="cursor-pointer" :icon="newPasswordToggleIcon" @click="toggleNewPasswordVisibility" />
									</b-input-group-append>
								</b-input-group>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group class="text-center">
						<validation-provider
							#default="{ errors }"
							name="confirm password"
							vid="confirmPassword"
							rules="required|min:6"
						>
							<label for="confirm-password" class="form-label mb-1">Confirm your new password</label>
							<b-input-group class="input-group-merge" >
								<b-form-input id="confirm-password" v-model="confirmPassword"
									class="form-control-merge" :type="confirmPasswordFieldType" name="confirm-password" />
								<b-input-group-append is-text>
									<feather-icon class="cursor-pointer" :icon="confirmPasswordToggleIcon" @click="toggleConfirmPasswordVisibility" />
								</b-input-group-append>
							</b-input-group>
							<small class="text-danger">{{ confirmPasswordError || errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-button class="mt-3" type="submit" variant="primary" block :disabled="(!newPassword || !confirmPassword)">
						Reset password
					</b-button>
				</b-form>
			</validation-observer>
		</div>

	</div>
</template>
  
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppLogo from '@core/layouts/components/Logo.vue'
import { required } from '@validations'
import service from '../service'

export default {
	components: {
		AppLogo,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			status: '',
			error: null,
			confirmPasswordError: null,
			sideImg: require('@/assets/images/pages/login-v2.svg'),
			required,
			newPasswordFieldType: 'password',
			confirmPasswordFieldType: 'password',
			newPassword: null,
			confirmPassword: null,
		}
	},
	computed: {
		newPasswordToggleIcon() {
			return this.newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		confirmPasswordToggleIcon() {
			return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		imgUrl() {
			if (this.$store.state.appConfig.layout.skin === 'dark') {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
				return this.sideImg
			}
			return this.sideImg
		},
		verify_token(){
			return this.$route.query.code;
		},
		email() {
			return this.$route.query.email;
		}
	},
	methods: {
		toggleNewPasswordVisibility() {
			this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password'
		},
		toggleConfirmPasswordVisibility() {
			this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
		},
		async setNewPassword(){
			this.error = null;
			this.confirmPasswordError = null
			const response = await service.setNewPassword({
				token: this.verify_token,
				password: this.newPassword,
				confirmPassword: this.confirmPassword,
				email: this.email,
			})
			if (response.data.type !== 'SUCCESS') {
				if (response.data.key === 'confirm_password_not_match'){
					this.isConfirmPassValidate = false;
					this.confirmPasswordError = 'Confirm new password does not match.'
				}
				if (response.data.key === 'incorrect_token'){
					this.error = 'Incorrect token'
				}
				return false
			}
			this.$router.push({ name: 'auth-login' });
		}
	},
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.reset-password-container {
	width: 100%;
	height: 1vh;
	background-image: url(https://goga.ai/wp-content/uploads/2022/01/2862666-1.png) !important;
	display: flex;
	align-items: center !important;
	padding: 5rem;
	flex-direction: column;
}

.common-text {
	color: #f1f1f1;
	font-size: 19px;
}
</style>
<style lang="scss" scoped>
.form-label {
	color: #f1f1f1;
	font-size: 19px;
	cursor: default;
	user-select: none;
}

.reset-password-form {
	width: 25%;
}
</style>
  